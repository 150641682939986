import React, { useState, useEffect } from 'react';
import { Container, Grid, Typography, Card, LinearProgress, CircularProgress } from '@mui/material';
import { styled } from '@mui/system';
import SecurityIcon from '@mui/icons-material/Security';
import VerifiedUserIcon from '@mui/icons-material/VerifiedUser';
import InsightsIcon from '@mui/icons-material/Insights';
import SpeedIcon from '@mui/icons-material/Speed';
import AccountBalanceWalletIcon from '@mui/icons-material/AccountBalanceWallet';
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer } from 'recharts';

const WhyChooseContainer = styled('div')({
  backgroundColor: '#1e1e1e',
  padding: '80px 20px',
  color: '#fff',
  textAlign: 'center',
});

const FeatureCard = styled(Card)({
  backgroundColor: '#292929',
  color: '#fff',
  borderRadius: '15px',
  padding: '20px',
  textAlign: 'center',
  boxShadow: '0 8px 30px rgba(0, 255, 0, 0.15)',
  transition: 'transform 0.4s, box-shadow 0.4s',
  '&:hover': {
    transform: 'scale(1.05)',
    boxShadow: '0 12px 40px rgba(0, 255, 0, 0.3)',
  },
});

const IconWrapper = styled('div')({
  fontSize: '3rem',
  marginBottom: '20px',
  color: '#0f0',
});

const ProgressWrapper = styled('div')({
  marginTop: '15px',
  textAlign: 'left',
});

const CircularWrapper = styled('div')({
  display: 'flex',
  justifyContent: 'center',
  marginTop: '30px',
});

const WhyChooseSection = () => {
  // Animated counters
  const [fraudPrevention, setFraudPrevention] = useState(0);
  const [chargebackReduction, setChargebackReduction] = useState(0);

  useEffect(() => {
    // Smoothly animate the counters
    const increment = (setState, finalValue) => {
      let start = 0;
      const step = Math.ceil(finalValue / 100);
      const interval = setInterval(() => {
        start += step;
        if (start >= finalValue) {
          setState(finalValue);
          clearInterval(interval);
        } else {
          setState(start);
        }
      }, 20);
    };

    increment(setFraudPrevention, 95); // 95% fraud prevention
    increment(setChargebackReduction, 85); // 85% chargeback reduction
  }, []);

  // Example fraud types breakdown data
  const fraudTypeData = [
    { type: 'Credit Card Fraud', occurrences: 100 },
    { type: 'ACH Fraud', occurrences: 50 },
    { type: 'Friendly Fraud', occurrences: 30 },
    { type: 'Chargeback Fraud', occurrences: 40 },
  ];

  return (
    <WhyChooseContainer>
      <Container maxWidth="lg">
        <Typography variant="h4" gutterBottom>
          Why Choose Fraud Buster API?
        </Typography>
        <Typography variant="subtitle1" paragraph>
          Fraud Buster API is designed to help businesses mitigate fraud, reduce chargebacks, and secure online transactions through advanced, AI-powered fraud detection.
        </Typography>

        <Grid container spacing={4}>
          {/* Feature 1 */}
          <Grid item xs={12} md={4}>
            <FeatureCard>
              <IconWrapper>
                <SecurityIcon fontSize="large" />
              </IconWrapper>
              <Typography variant="h6">Advanced Fraud Detection</Typography>
              <Typography variant="body2">
                Leverage cutting-edge AI to detect fraudulent activities in real time, preventing costly chargebacks and fraudulent transactions.
              </Typography>
              <ProgressWrapper>
                <Typography variant="body2" gutterBottom>
                  Fraud Detection Efficiency: {fraudPrevention}%
                </Typography>
                <LinearProgress variant="determinate" value={fraudPrevention} color="primary" />
              </ProgressWrapper>
            </FeatureCard>
          </Grid>

          {/* Feature 2 */}
          <Grid item xs={12} md={4}>
            <FeatureCard>
              <IconWrapper>
                <VerifiedUserIcon fontSize="large" />
              </IconWrapper>
              <Typography variant="h6">Secure & Reliable</Typography>
              <Typography variant="body2">
                Fraud Buster API ensures your business and customer data remain secure at all times.
              </Typography>
              <CircularWrapper>
                <CircularProgress variant="determinate" value={fraudPrevention} size={100} thickness={4} />
              </CircularWrapper>
              <Typography variant="body2" mt={2}>
                95% Fraud Prevention
              </Typography>
            </FeatureCard>
          </Grid>

          {/* Feature 3 */}
          <Grid item xs={12} md={4}>
            <FeatureCard>
              <IconWrapper>
                <AccountBalanceWalletIcon fontSize="large" />
              </IconWrapper>
              <Typography variant="h6">Reduce Chargebacks</Typography>
              <Typography variant="body2">
                Save your business from costly chargeback fees by preventing fraudulent transactions.
              </Typography>
              <ProgressWrapper>
                <Typography variant="body2" gutterBottom>
                  Chargeback Reduction: {chargebackReduction}%
                </Typography>
                <LinearProgress variant="determinate" value={chargebackReduction} color="secondary" />
              </ProgressWrapper>
            </FeatureCard>
          </Grid>
        </Grid>

        {/* Fraud Type Breakdown - Interactive Chart */}
        <Typography variant="h5" gutterBottom style={{ marginTop: '40px' }}>
          Fraud Breakdown by Type
        </Typography>
        <Typography variant="body2" paragraph>
          Analyze the most common fraud types affecting businesses.
        </Typography>
        <ResponsiveContainer width="100%" height={400}>
          <BarChart data={fraudTypeData}>
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey="type" />
            <YAxis />
            <Tooltip />
            <Bar dataKey="occurrences" fill="#ff7300" name="Occurrences" />
          </BarChart>
        </ResponsiveContainer>
      </Container>
    </WhyChooseContainer>
  );
};

export default WhyChooseSection;
