import React, { useState, useEffect } from 'react';
import {
  Container,
  Typography,
  Grid,
  Card,
  CardContent,
  Button,
  TextField,
  Snackbar,
  Alert,
} from '@mui/material';
import { styled } from '@mui/system';
import BusinessIcon from '@mui/icons-material/Business';
import VerifiedUserIcon from '@mui/icons-material/VerifiedUser';
import ShieldIcon from '@mui/icons-material/Shield';

const ContactContainer = styled('div')({
  backgroundColor: '#141414',
  padding: '80px 20px',
  textAlign: 'center',
  color: '#fff',
  position: 'relative',
  overflow: 'hidden',
});

const AnimatedBackground = styled('div')({
  position: 'absolute',
  top: '-50%',
  left: '-50%',
  width: '200%',
  height: '200%',
  background: 'radial-gradient(circle at center, rgba(0, 255, 0, 0.2), transparent)',
  animation: 'rotation 20s infinite linear',
  zIndex: 0,
  '@keyframes rotation': {
    from: { transform: 'rotate(0deg)' },
    to: { transform: 'rotate(360deg)' },
  },
});

const LeadCard = styled(Card)({
  backgroundColor: '#1e1e1e',
  borderRadius: '15px',
  boxShadow: '0 8px 30px rgba(0, 255, 0, 0.15)',
  color: '#fff',
  position: 'relative',
  zIndex: 1,
  padding: '20px',
});

const SectionTitle = styled(Typography)({
  fontSize: '2.5rem',
  fontWeight: 'bold',
  marginBottom: '40px',
  position: 'relative',
  zIndex: 1,
});

const LeadSubtitle = styled(Typography)({
  fontSize: '1.25rem',
  marginBottom: '40px',
  position: 'relative',
  zIndex: 1,
});

const StyledForm = styled('form')({
  display: 'flex',
  flexDirection: 'column',
  gap: '20px',
});

const SubmitButton = styled(Button)({
  backgroundColor: '#0f0',
  color: '#000',
  padding: '12px 30px',
  borderRadius: '30px',
  fontWeight: 'bold',
  fontSize: '1rem',
  '&:hover': {
    backgroundColor: '#0fff0f',
  },
});

const StatsContainer = styled(Grid)({
  marginTop: '60px',
  position: 'relative',
  zIndex: 1,
});

const StatCard = styled(Card)({
  backgroundColor: 'transparent',
  boxShadow: 'none',
  textAlign: 'center',
  color: '#fff',
});

const StatIcon = styled('div')({
  fontSize: '3rem',
  color: '#0f0',
  marginBottom: '10px',
});

const ContactSection = () => {
  const [formData, setFormData] = useState({ company_name: '', email: '', phone: '', message: '' });
  const [successMessage, setSuccessMessage] = useState(false);
  const [errorMessage, setErrorMessage] = useState(false);

  // Animated counters
  const [businessesProtected, setBusinessesProtected] = useState(0);
  const [fraudTransactions, setFraudTransactions] = useState(0);
  const [yearsExperience, setYearsExperience] = useState(0);

  useEffect(() => {
    // Animate counters
    const animateValue = (setValue, target) => {
      let start = 0;
      const duration = 2000;
      const increment = target / (duration / 50);
      const interval = setInterval(() => {
        start += increment;
        if (start >= target) {
          setValue(Math.floor(target));
          clearInterval(interval);
        } else {
          setValue(Math.floor(start));
        }
      }, 50);
    };

    animateValue(setBusinessesProtected, 100);
    animateValue(setFraudTransactions, 100000);
    animateValue(setYearsExperience, 7);
  }, []);

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await fetch('https://formspree.io/f/xyzyygwl', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(formData),
      });

      if (response.ok) {
        setSuccessMessage(true);
        setFormData({ company_name: '', email: '', phone: '', message: '' });
      } else {
        setErrorMessage(true);
      }
    } catch (error) {
      setErrorMessage(true);
    }
  };

  const handleClose = () => {
    setSuccessMessage(false);
    setErrorMessage(false);
  };

  return (
    <ContactContainer>
      <AnimatedBackground />
      <Container maxWidth="md">
        <SectionTitle variant="h3">
          Join {businessesProtected}+ Businesses Protecting Themselves Against Fraud
        </SectionTitle>
        <LeadSubtitle>
          Let us help you secure your transactions and reduce chargebacks. Submit your details below, and our team will get in touch to kickstart your fraud prevention process.
        </LeadSubtitle>
        <Grid container spacing={4} justifyContent="center">
          <Grid item xs={12} sm={8}>
            <LeadCard>
              <CardContent>
                <Typography variant="h5" gutterBottom>
                  Contact Us to Get Started
                </Typography>
                <StyledForm onSubmit={handleSubmit}>
                  <TextField
                    name="company_name"
                    label="Company Name"
                    variant="outlined"
                    fullWidth
                    required
                    value={formData.company_name}
                    onChange={handleChange}
                    InputLabelProps={{ style: { color: '#fff' } }}
                    InputProps={{
                      style: { color: '#fff' },
                    }}
                  />
                  <TextField
                    name="email"
                    label="Your Email"
                    variant="outlined"
                    fullWidth
                    required
                    type="email"
                    value={formData.email}
                    onChange={handleChange}
                    InputLabelProps={{ style: { color: '#fff' } }}
                    InputProps={{
                      style: { color: '#fff' },
                    }}
                  />
                  <TextField
                    name="phone"
                    label="Phone Number"
                    variant="outlined"
                    fullWidth
                    required
                    value={formData.phone}
                    onChange={handleChange}
                    InputLabelProps={{ style: { color: '#fff' } }}
                    InputProps={{
                      style: { color: '#fff' },
                    }}
                  />
                  <TextField
                    name="message"
                    label="Message (Tell us your fraud concerns)"
                    multiline
                    rows={4}
                    variant="outlined"
                    fullWidth
                    required
                    value={formData.message}
                    onChange={handleChange}
                    InputLabelProps={{ style: { color: '#fff' } }}
                    InputProps={{
                      style: { color: '#fff' },
                    }}
                  />
                  <SubmitButton type="submit" variant="contained">
                    Get in Touch
                  </SubmitButton>
                </StyledForm>
              </CardContent>
            </LeadCard>
          </Grid>
        </Grid>

        {/* Stats Section */}
        <StatsContainer container spacing={4}>
          <Grid item xs={12} sm={4}>
            <StatCard>
              <StatIcon>
                <BusinessIcon fontSize="large" />
              </StatIcon>
              <Typography variant="h4">{businessesProtected}+</Typography>
              <Typography variant="subtitle1">Businesses Protected</Typography>
            </StatCard>
          </Grid>
          <Grid item xs={12} sm={4}>
            <StatCard>
              <StatIcon>
                <ShieldIcon fontSize="large" />
              </StatIcon>
              <Typography variant="h4">{fraudTransactions.toLocaleString()}</Typography>
              <Typography variant="subtitle1">Fraudulent Transactions Prevented</Typography>
            </StatCard>
          </Grid>
          <Grid item xs={12} sm={4}>
            <StatCard>
              <StatIcon>
                <VerifiedUserIcon fontSize="large" />
              </StatIcon>
              <Typography variant="h4">{yearsExperience}+</Typography>
              <Typography variant="subtitle1">Years of Experience</Typography>
            </StatCard>
          </Grid>
        </StatsContainer>

        {/* Success and Error Message */}
        <Snackbar open={successMessage} autoHideDuration={6000} onClose={handleClose}>
          <Alert onClose={handleClose} severity="success" sx={{ width: '100%' }}>
            Thank you for submitting your details. We will be in touch soon!
          </Alert>
        </Snackbar>

        <Snackbar open={errorMessage} autoHideDuration={6000} onClose={handleClose}>
          <Alert onClose={handleClose} severity="error" sx={{ width: '100%' }}>
            Oops! Something went wrong. Please try again.
          </Alert>
        </Snackbar>
      </Container>
    </ContactContainer>
  );
};

export default ContactSection;
