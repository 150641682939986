import React from 'react';
// import Header from './components/Header';
import HeroSection from './components/HeroSection';
import FeaturesSection from './components/FeaturesSection';
import ContactSection from './components/ContactSection';
import Footer from './components/Footer';
import CaseStudySection from './components/CaseStudySection';
import WhyChooseSection from './components/WhyChooseSection';
import VideoSection from './components/VideoSection';
// import EvaluationSection from './components/EvaluationSection';

const App = () => {
  return (
    <div style={{ backgroundColor: '#0b0b0b', color: '#fff', minHeight: '100vh' }}>
      {/* <Header /> */}
      <HeroSection />
      <FeaturesSection />
      <VideoSection />
      <CaseStudySection />
      {/* <EvaluationSection /> */}
      <WhyChooseSection />

      <ContactSection /> {/* Add the PricingSection here */}
      <Footer />
    </div>
  );
};

export default App;
