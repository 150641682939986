import React from 'react';
import { Container, Grid, Typography, Card } from '@mui/material';
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer, BarChart, Bar, PieChart, Pie, Cell, Legend } from 'recharts';
import { styled } from '@mui/system';

const CaseStudyContainer = styled('div')(({ theme }) => ({
  backgroundColor: '#1e1e1e',
  padding: '80px 20px',
  color: '#fff',
  textAlign: 'center',
}));

const ChartCard = styled(Card)(({ theme }) => ({
  backgroundColor: '#292929',
  color: '#fff',
  borderRadius: '15px',
  boxShadow: '0 8px 30px rgba(0, 255, 0, 0.25)',
  padding: '20px',
  width: '100%',
  margin: '20px 0',
}));

const SectionTitle = styled(Typography)(({ theme }) => ({
  margin: '40px 0 20px',
  fontSize: '1.5rem',
  fontWeight: 'bold',
}));

const TextContainer = styled(Grid)(({ theme }) => ({
  padding: '20px',
}));

const StyledGrid = styled(Grid)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  textAlign: 'left',
}));

const CaseStudySection = () => {
  const COLORS = ['#0f0', '#ff7300', '#00C49F', '#FFBB28'];

  const caseStudyData = [
    { month: 'Jan', fraudAttempts: 200, prevented: 180 },
    { month: 'Feb', fraudAttempts: 250, prevented: 240 },
    { month: 'Mar', fraudAttempts: 220, prevented: 210 },
    { month: 'Apr', fraudAttempts: 300, prevented: 290 },
    { month: 'May', fraudAttempts: 320, prevented: 310 },
  ];

  const chargebackData = [
    { month: 'Jan', chargebacks: 50, prevented: 45 },
    { month: 'Feb', chargebacks: 60, prevented: 55 },
    { month: 'Mar', chargebacks: 45, prevented: 43 },
    { month: 'Apr', chargebacks: 70, prevented: 65 },
    { month: 'May', chargebacks: 75, prevented: 70 },
  ];

  const fraudTypeData = [
    { type: 'Credit Card Fraud', occurrences: 100 },
    { type: 'ACH Fraud', occurrences: 50 },
    { type: 'Friendly Fraud', occurrences: 30 },
    { type: 'Chargeback Fraud', occurrences: 40 },
  ];

  const chargebackRecoveryData = [
    { type: 'Recovered', value: 85 },
    { type: 'Not Recovered', value: 15 },
  ];

  const fraudByCategoryData = [
    { category: 'E-commerce', fraudAttempts: 150, prevented: 140 },
    { category: 'Subscription Services', fraudAttempts: 120, prevented: 110 },
    { category: 'Marketplaces', fraudAttempts: 170, prevented: 160 },
    { category: 'Travel', fraudAttempts: 90, prevented: 85 },
  ];

  return (
    <CaseStudyContainer>
      <Container maxWidth="lg">
        <Typography variant="h4" gutterBottom>
          Case Study: Reducing Chargebacks and Fraud with Fraud Buster API
        </Typography>
        <Typography variant="subtitle1" paragraph>
          Discover how our fraud prevention system helped an e-commerce business significantly reduce chargebacks and prevent fraudulent transactions.
        </Typography>

        {/* Fraud Attempts vs Fraud Prevention */}
        <StyledGrid container spacing={4}>
          <Grid item xs={12} md={6}>
            <ChartCard>
              <Typography variant="h6" gutterBottom>
                Fraud Attempts vs Fraud Prevented (2023)
              </Typography>
              <ResponsiveContainer width="100%" height={300}>
                <LineChart data={caseStudyData}>
                  <CartesianGrid strokeDasharray="3 3" />
                  <XAxis dataKey="month" />
                  <YAxis />
                  <Tooltip />
                  <Legend />
                  <Line type="monotone" dataKey="fraudAttempts" stroke="#ff7300" />
                  <Line type="monotone" dataKey="prevented" stroke="#0f0" />
                </LineChart>
              </ResponsiveContainer>
            </ChartCard>
          </Grid>
          <Grid item xs={12} md={6}>
            <TextContainer>
              <Typography variant="h5" gutterBottom>
                Fraud Prevention Impact
              </Typography>
              <Typography variant="body1" paragraph>
                This chart highlights how Fraud Buster API helped the business prevent nearly all fraud attempts, saving significant revenue in just five months.
              </Typography>
            </TextContainer>
          </Grid>
        </StyledGrid>

        {/* Chargebacks vs Prevention */}
        <StyledGrid container spacing={4}>
          <Grid item xs={12} md={6}>
            <TextContainer>
              <Typography variant="h5" gutterBottom>
                Chargeback Prevention Success
              </Typography>
              <Typography variant="body1" paragraph>
                Chargeback disputes were significantly reduced through the AI solution, allowing the business to save on costly disputes and recover lost revenue.
              </Typography>
            </TextContainer>
          </Grid>
          <Grid item xs={12} md={6}>
            <ChartCard>
              <Typography variant="h6" gutterBottom>
                Chargebacks vs Chargebacks Prevented
              </Typography>
              <ResponsiveContainer width="100%" height={300}>
                <LineChart data={chargebackData}>
                  <CartesianGrid strokeDasharray="3 3" />
                  <XAxis dataKey="month" />
                  <YAxis />
                  <Tooltip />
                  <Legend />
                  <Line type="monotone" dataKey="chargebacks" stroke="#ff7300" />
                  <Line type="monotone" dataKey="prevented" stroke="#0f0" />
                </LineChart>
              </ResponsiveContainer>
            </ChartCard>
          </Grid>
        </StyledGrid>

        {/* Fraud Types Breakdown */}
        <StyledGrid container spacing={4}>
          <Grid item xs={12} md={6}>
            <ChartCard>
              <Typography variant="h6" gutterBottom>
                Fraud Types Breakdown
              </Typography>
              <ResponsiveContainer width="100%" height={300}>
                <BarChart data={fraudTypeData}>
                  <CartesianGrid strokeDasharray="3 3" />
                  <XAxis dataKey="type" />
                  <YAxis />
                  <Tooltip />
                  <Legend />
                  <Bar dataKey="occurrences" fill="#8884d8" />
                </BarChart>
              </ResponsiveContainer>
            </ChartCard>
          </Grid>
          <Grid item xs={12} md={6}>
            <TextContainer>
              <Typography variant="h5" gutterBottom>
                Breakdown of Fraud Types
              </Typography>
              <Typography variant="body1" paragraph>
                By understanding fraud types, businesses can target prevention strategies more effectively. Our system addresses a variety of fraud types, ensuring comprehensive protection.
              </Typography>
            </TextContainer>
          </Grid>
        </StyledGrid>

        {/* Chargeback Recovery */}
        <StyledGrid container spacing={4}>
          <Grid item xs={12} md={6}>
            <TextContainer>
              <Typography variant="h5" gutterBottom>
                Chargeback Recovery Rate
              </Typography>
              <Typography variant="body1" paragraph>
                Recovering chargebacks is crucial for maintaining a healthy business. Our system allows significant revenue recovery, as shown by the high recovery rate.
              </Typography>
            </TextContainer>
          </Grid>
          <Grid item xs={12} md={6}>
            <ChartCard>
              <Typography variant="h6" gutterBottom>
                Chargeback Recovery Rate
              </Typography>
              <ResponsiveContainer width="100%" height={300}>
                <PieChart>
                  <Pie
                    data={chargebackRecoveryData}
                    dataKey="value"
                    nameKey="type"
                    cx="50%"
                    cy="50%"
                    outerRadius={100}
                    label
                  >
                    {chargebackRecoveryData.map((entry, index) => (
                      <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                    ))}
                  </Pie>
                  <Legend />
                  <Tooltip />
                </PieChart>
              </ResponsiveContainer>
            </ChartCard>
          </Grid>
        </StyledGrid>

        {/* Summary Section */}
        <SectionTitle variant="h5">
          The Impact
        </SectionTitle>
        <Typography variant="body1" paragraph>
          By integrating Fraud Buster API, the e-commerce retailer prevented over 90% of fraud attempts and reduced chargebacks by 85%. This led to over $750,000 in recovered revenue and reduced operational costs.
        </Typography>
      </Container>
    </CaseStudyContainer>
  );
};

export default CaseStudySection;
